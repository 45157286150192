.search-pagesize {
  margin-top: 5px !important;
}

.search-pagesize .dropdown-menu {
  min-width: 100%;
}

.algolia-pagination > li.page-item {
  padding: 4px;
  font-size: 1rem;
}

.algolia-pagination > li.page-item a.page-link{
  color: rgba(0,0,0,.5);
  border-radius: 4px;
  width: 38px;
  text-align: center;
  padding: 6px;
}

.algolia-pagination > li.page-item.active a.page-link{
  color: #fff;;
  background-color: #6c757d;
  border-color: #6c757d;
}

.algolia-pagination > li.page-item.active a.page-link:hover{
  background-color: #5a6268;
  border-color: #545b62;
}