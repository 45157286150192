.rowitem{
    margin: 20px;
    margin-bottom: 40px !important;
    border: 1px solid #a6acb3; 
  }

  .rowhr{
    border-top: 1px solid #a6acb3; 
  }
  
  .rowfilter{
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 15px;
    padding-top:5px;
  }
  
  .rowfilterby{
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 5px;
  }
  
  
  .colitems{
    background-color: #E8E8E8;
    font-size: small;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
  }
  
  .custom-col3{
    max-width: 24%;
    flex: 0 0 24%;
  }
  
  .custom-col1{
    max-width: 11.333333%;
    flex: 0 0 11.333333%;
  }
  
  
  .colitem{
    display: block;
    /* font-weight: bold; */
  }
  
  .colbutton{
    background-color: #E8E8E8;
    display: grid;
    align-items: center;
  }
  
  .statusbutton{
    font-size: x-small;
    padding:4px;
    line-height: 2;
    opacity: 1 !important;
    color: white;
  }
  
  .algoliaselect .ais-MenuSelect-select{
    width: 100%;
    background-color: #E8E8E8;
    border-radius: 5px;
    border:0px;
    padding-left: 10px;
    height: 40px;
  }
  
  .algoliasearch .ais-SearchBox-input{
    width: 95%;
    background-color: #BDEDFF;
    /* color: #0096db; */
    border-radius: 4px;
    line-height: 2.5;
    border: 0px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding-left: 10px
  }
  
  
  .algoliasearch .ais-SearchBox-submit{
    width: 5%;
    line-height: 2.5;
    border: 0px;
    background-color: #0096db;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .algoliasearch .ais-SearchBox-submitIcon{
    fill: white;
  }
  
  .algoliasearch .ais-SearchBox-reset{
    display: none !important;
  }
  
  
  /* .ais-InstantSearch__root {
    display: flex;
    border: 1px solid grey;
    list-style: none;
  } */
  
   .ais-HitsPerPage{
    display: inline-block;
  }
  
  .algoliahitsperpage .ais-HitsPerPage-select{
    padding: 0.3rem 0.6rem;
    display: block;
    border: 1px solid #c4c8d8;
    border-radius: 5px;
    -webkit-transition: background-color 0.2s ease-out;
    transition: background-color 0.2s ease-out;
  }
  
  .ais-Pagination{
    display: inline-block;
  }
  
  .ais-Pagination-list {
    display: flex;
    list-style: none;
    padding-left: 20px ;
  }
  
  .ais-Pagination-item  {
    display: inline-block;
    list-style: none;
    display: 'inline-block';
    text-decoration: 'none';
  }
  
  a[class^='ais-'] {
    text-decoration: none;
  }
  
   .ais-Pagination-list {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .ais-Pagination-item + .ais-Pagination-item {
    margin-left: 0.3rem;
  }
  .ais-Pagination-link {
    padding: 0.3rem 0.6rem;
    display: block;
    border: 1px solid #c4c8d8;
    border-radius: 5px;
    -webkit-transition: background-color 0.2s ease-out;
    transition: background-color 0.2s ease-out;
  }
  .ais-Pagination-link:hover, .ais-Pagination-link:focus {
    background-color: #e3e5ec;
  }
  .ais-Pagination-item--disabled .ais-Pagination-link {
    opacity: 0.6;
    cursor: not-allowed;
    color: #a5abc4;
  }
  .ais-Pagination-item--disabled .ais-Pagination-link:hover, .ais-Pagination-item--disabled .ais-Pagination-link:focus {
    color: #a5abc4;
    background-color: #fff;
  }
  .ais-Pagination-item--selected .ais-Pagination-link {
    color: #fff;
    background-color: #0096db;
    border-color: #0096db;
  }
  
  .ais-Pagination-item--selected .ais-Pagination-link:hover, .ais-Pagination-item--selected .ais-Pagination-link:focus {
    color: #fff;
  }
  
  
  .companyAvatar{
    height: 40px !important;
    width: 40px !important;
    object-fit: cover;
  }
  
  .companyCategory{
    border: 1px solid;
    color: gray;
    border-radius: 5px;
    padding: 5px;
  }
  
  .ordercolitems{
    font-size: small;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    align-self: center;
  }
  
  .orderPackageStatus{
    border: 1px solid;
    border-radius: 5px;
    padding: 0.3rem 0.3rem;
    /* line-height: 2.5; */
    display: inline-block;
    width: 92%;
    text-align: center;
  }
  
  
  .datepicker {
    margin: 2px 0px 2px 0px;
    height: 60px;
    width: fit-content;
    padding: 20px 15px 10px;
    border-bottom: 2px solid lightGrey;
    background-color: #fff;
    color: #2c2c2c;
    width: 300px;
    font-weight: 600;
  }
  
  .react-datepicker-wrapper,
  .react-datepicker__input-container,
  .react-datepicker__input-container input {
      /* display: flex;
      float: none; */
      background: #E8E8E8;
      border-radius: 5px;
      border: 0px;
      font-size: small;
      width: 100%;
      height: 100%;
      min-height: 35px;
  }
  
  .moreItemsRow{
    background-color: #BDEDFF;
    color: #0096db;
  }
  
  .slaRow{
    background-color: lightpink;
    color: #FE0000;
  }

  .btn-primary {
    color: #fff;
    background-color: #343a40 !important;
    border-color: #343a40 !important;
  }