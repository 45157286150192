
@media (min-width: 992px) {
  #changePassword .m-width {
    max-width: 87%;
  }
}

@media (min-width: 1115px) {
  #changePassword .m-width {
    max-width: 76%;
  }
}

@media (min-width: 1460px) {
  #changePassword .m-width {
    max-width: 64%;
  }
}




