.file-attachment-btn-label {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  min-width: 100%;
  min-height: 100%;
  cursor: inherit;
  opacity: 0;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.6em + 1.625rem);
  margin-bottom: 0;
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  font-weight: 400;
  line-height: 1.6;
  color: #8c98a4;
  background-color: #fff;
  border-radius: 0.3125rem;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.6em + 1.625rem);
  margin: 0;
  opacity: 0;
}