.image-gallery-thumbnails-container{
  border-top: 1px solid #dee2e6 !important;
  padding-top: 6px;
}

.countryImage{
  border-radius: 10px;
  width: 65px;
  height: 35px;
  object-fit: cover;
}