.totalText {
  font-size: 0.9rem;
  font-weight: bold;
  color: #000;
  margin-top: 5px;
}

.poStatus {
  font-size: 1rem;
  color: #000;
  padding-right: 15px;
  margin-top: 10px;
}

.statusTag {
  padding: 10px;
  border-radius: 5px;
  border-width: 1px;
  border-color: lightgray;
  border-style: solid;
}

.shippingAndBillingContainer {
  border-style: solid; 
  border-width: 1px; 
  border-radius: 5px;
  border-color: lightgray;
  padding: 20px;
  height: 200px;
}

.remarks {
  flex: 0.9;
  border-radius: 5px;
  border-color: lightgray;
  padding: 10px;
  resize: none;
}