.border-top-widget {
  border-top: 4px solid #88c4ff !important;
}

.pill-widget {
  font-size: .90em !important;
  border-radius: 14px !important;
  height: 25px !important;
  min-width: 25px !important;
  padding: 6px 5px 3px 4px !important;
}

.border-top-widget-orange {
  border-top: 4px solid #f79b24 !important;
}

.border-top-widget-red {
  border-top: 4px solid #fd5e5e !important;
}

.border-top-widget-grey {
  border-top: 4px solid #cfcfcf !important;
}

.widget-link:hover{
  cursor: pointer;
}